import styles from './FooterAddress.module.scss'

const FooterAddress = () => {
  return (
    <div className={styles.root}>
      <p className="mb-4">
        <span className={styles.title}>PFISTER KREUZFAHRTEN AG</span>
        <br />
        Rudolfstrasse 37
        <br />
        8400 Winterthur
      </p>
      <p className="mb-4">
        {/*<span className={styles.country}>CH</span>*/}
        <a href="tel:0041445110022">044 511 00 22</a>
        <br />

        <a href="mailto:info@pfister-kreuzfahrten.ch">info@pfister-kreuzfahrten.ch</a>
      </p>
    </div>
  )
}

export default FooterAddress
